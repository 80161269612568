<!-- <div class="m-auto mx-4 p-4 my-4 mt-0 pt-0 flex-grow"> -->


<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-indigo-500 m-auto mx-4 p-4 my-4 mt-0 pt-0 flex-grow">
    <div class="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
      <h2 class="text-3xl font-extrabold text-white sm:text-4xl">
        <span class="block text-3xl mb-2">تسجيل بيانات بالمنظومة</span>
        <span class="block text-2xl">تأكد من إدخال جميع البيانات بشكل صحيح</span>
      </h2>
      <p class="mt-4 text-lg leading-6 text-indigo-200">Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla nec.</p>
      <router-link :to="{name: 'RegistrationDemographic'}" class="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50 sm:w-auto">
        ابدأ في تسجيل البيات
      </router-link>
    </div>
  </div>
</template>
